import * as React from "react"
import HorseButton from "../../../components/horse-button"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

import TheHorseYouKnow from "../../../images/the-horse-you-know.png"

const Horse1 = () => (
  <Layout bodyClass="the-horse-you-know">
    <Seo title="Some Horses Have Mustaches" />
    <img
      src={TheHorseYouKnow}
      alt="The Horse You Know"
      className="thyn-image"
    />
    <h1>Did you know?</h1>
    <h2>
      Some horses have <span>mustaches</span>.
    </h2>
    <p>
      When I found this out, I thought the internet was playing a prank on me.
      After scrutinizing many photos and learning about horse breeds, turns out:
      they totally do. And they're glorious.
    </p>
    <p>
      "Well, where are the PHOTOS?! Why would you say something so incredible
      and then withhold the photo?" I hear you. I couldn't find any pictures
      that I had the rights to embed here, but I did find an absolutely lovely
      site full of pictures of mustachioed horses in all their glory. Please do
      yourself a favor and check out this{" "}
      <a href="https://horseyhooves.com/horse-mustaches/">
        blog post about horses with mustaches
      </a>
      .
    </p>
    <div className="button-holder">
      <HorseButton />
    </div>
  </Layout>
)

export default Horse1
